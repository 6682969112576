import RecommendationService from '@/services/recommendation.service';
import { Notification } from 'element-ui';
import { maxQueueLength } from '@/services/recommendation.service';

export default {
    computed: {
        roomScoreClasses() {
            return function (score) {
                let classes = 'venue-rating';

                if (score >= 0 && score <= 33.3) {
                    classes += ' low';
                } else if(score > 33.3 && score <= 66.66) {
                    classes += ' med';
                } else {
                    classes += ' high';
                }

                return classes;
            }
        },
        roomsSelectedForRecommendation() {
            return this.$store.getters['recommendations/getSelectedVenueRoomsForRecommendation'](this.leadEventId);
        },
    },
	methods: {
		async viewRecommendationsPortal() {
			this.recommendationPortalLoading = true;

			const link = await RecommendationService.getRecommendationPortalLink(this.leadId, true);
			const recommendationPortalLink = link.data;
			const id = (new Date()).getTime();
			window.open(recommendationPortalLink , '_blank', id);
			this.recommendationPortalLoading = false;
		},

		async searchVenues(leadId, eventId, searchFilters = {}, correctedInvalidDataFromUser = {}) {
			let params = {
				lead_id: leadId,
				event_id: eventId
			};

			if (Object.keys(searchFilters).length) {
				params.filters = JSON.stringify(searchFilters);
			}

            if (Object.keys(correctedInvalidDataFromUser).length) {
                params.corrected_invalid_data = JSON.stringify(correctedInvalidDataFromUser);
            }

			return await this.$store.dispatch("venues/karenSearch", params);
		},

        updateKarenListingPageData(data) {
			if (data.venues) {
                this.$store.commit('recommendations/updateKarenVenuesForListView', data.venues);
			}

			if (data.searchCriteria) {
				this.$emit('update:searchCriteria', data.searchCriteria);
				this.$emit('update:currentSearchCriteria', data.searchCriteria);
			}

			if (data.defaultSearchCriteria) {
				this.$emit('update:defaultSearchCriteria', data.defaultSearchCriteria);
			}

            if (data.selectableLocations) {
				this.$emit('update:selectableLocations', data.selectableLocations);
			}

			if (data.venueStyles) {
				this.$emit('update:venueStyles', data.venueStyles);
			}
        },

        updateData(result) {
            if (result.venues) {
                this.$store.commit('recommendations/updateKarenVenuesForListView', result.venues);

                let karenVenuesForListView = this.$store.getters['recommendations/getKarenVenuesForListView']('all');
                let venuesForMapView = {};
                for (const venueLocationGroupName in karenVenuesForListView) {
                    if (['eventCityVenues', 'otherVenues'].includes(venueLocationGroupName)) {
                        karenVenuesForListView[venueLocationGroupName].forEach(venueRooms => {
                            if (venueRooms.length) {
                                venuesForMapView[venueRooms[0].venue_id] = venueRooms;
                            }
                        });
                    }
                }

                this.$store.commit('recommendations/updateKarenVenuesForMapView', venuesForMapView);
            }

            if (result.searchCriteria) {
                this.searchCriteria = result.searchCriteria;
                this.currentSearchCriteria = result.searchCriteria;
            }

            if (result.defaultSearchCriteria) {
                this.defaultSearchCriteria = result.defaultSearchCriteria;
            }

            if (result.selectableLocations) {
                this.cities = result.selectableLocations;
            }

            if (result.venueStyles) {
                this.venueStyles = result.venueStyles;
            }
        },
        addToQueueInStore(venueRoom) {
            if (this.roomsSelectedForRecommendation.length >= maxQueueLength) {
                Notification.error({
                    title: 'Recommendation Limit Reached',
                    message:
                        `You have reached the maximum limit of ${maxQueueLength} recommended venues/rooms. Please review your selections.`,
                    showClose: true
                })

                return;
            }

            let temp = this.roomsSelectedForRecommendation
            temp.push(venueRoom)
            this.$store.commit('recommendations/updateSelectedForRecommendation', {
                leadEventId: this.leadEventId,
                rooms: temp
            })
        },
        removeFromQueueInStore(venueRoom) {
            let temp = this.roomsSelectedForRecommendation;

            let index = -1;
            index = temp.indexOf(venueRoom);

            if (index > -1) {
                temp.splice(index, 1);
            }

            this.$store.commit('recommendations/updateSelectedForRecommendation', {
                leadEventId: this.leadEventId,
                rooms: temp,
            });
        }
	}
};