import { HTTP } from '.';
import buildQuery from '@/helpers/url';
import moment from 'moment';
import momenttz from 'moment-timezone';

export default new class VenueAuditLog {
  constructor() {}

  async paginate(page, search, country, show_pending_only) {
    let params = {
      page: page || 1,
      search: search || '',
      country,
      show_pending_only: show_pending_only ? 1 : 0,
    };

    return await HTTP.get('/v1/reports/lost-wons?' + buildQuery(params));
  }

  async updateApproval( leadEventId, id, status) {
    return await HTTP.put('/v1/sales/lead-event/' + leadEventId + '/approval' , {
      status,
      id,
    });
  }

  async getVenueSaleData(venue_id, leadEventId) {
    let data = await HTTP.get('/v1/venues/' + venue_id + '/lead-event/' + leadEventId + '/lead-venue-sale');
    return data.data;
  }
  
}();