import { HTTP } from '../services'
import buildQuery from '@/helpers/url'
import { store } from '@/store'

export default new (class VenueService {
	getAll (params) {
		if (params) {
			params = buildQuery(params)
		}
		return HTTP.get('/v1/venues?' + params)
	}

	getVenueById (data) {
		return HTTP.get('/v1/venues/' + data.id + '?lead_id=' + data.lead)
	}

	getVenueLeads (id, page) {
		let params = {
			page: page || 1
		}
		return HTTP.get('/v1/venues/' + id + '/leads?' + buildQuery(params))
	}

	getVenueEmailLogs (id, page) {
		let params = {
			page: page || 1,
			id: id
		}
		return HTTP.get('/v1/venue-email-logs?' + buildQuery(params))
	}

	getVenueLostStatus () {
		return HTTP.get('/v1/venues/lost_status')
	}

	revokeInspectionEmailCalendarAccess (id, is_corporate) {
		return HTTP.post('/v1/venues/' + id + '/revoke_old_email_calendar_access', {
			is_corporate
		})
	}

	getVenueRecommendations (id, page) {
		let params = {
			page: page || 1
		}
		return HTTP.get(
			'/v1/venues/' + id + '/recommendations?' + buildQuery(params)
		)
	}

	getVenueStyles () {
		return HTTP.get('/v1/venues/styles')
	}

	getVenueRegions (country) {
		return HTTP.get('/v1/venues/regions' + (country ? '?country=' + country : ''))
	}

	getVenueCities (country) {
		return HTTP.get('/v1/venues/cities?country=' + country )
	}

	getVenueSpecialists () {
		return HTTP.get('/v1/venues/venue_specialists')
	}

	searchVenues (params) {
		return HTTP.get('/v1/venues/search?' + buildQuery(params))
	}

	karenVenueSearch (params) {
		return HTTP.get('/v1/venues/karen/search?' + buildQuery(params))
	}

	venueDescription (params) {
		return HTTP.get(
			'/v1/venues/' + params.venue_id + '/description?' + buildQuery(params)
		)
	}

	venuePackages (params) {
		return HTTP.get(
			'/v1/venues/' + params.venue_id + '/packages?' + buildQuery(params)
		)
	}

	getVenueNotes (params) {
		return HTTP.get(
			'/v1/venues/' + params.venue_id + '/notes?' + buildQuery(params)
		)
	}

	getTopVenuesLossTo (params) {
		return HTTP.get('/v1/venues/' + params.venue_id + '/top_venues_loss_to')
	}

	getLostReasons (params) {
		return HTTP.get('/v1/venues/' + params.venue_id + '/lost_reasons')
	}

	getVenueLinkedEmails (params) {
		return HTTP.get('/v1/venues/' + params.venue_id + '/linked_emails')
	}

	linkNewEmail (venue_id, email) {
		return HTTP.post('/v1/venues/' + venue_id + '/link_new_email', {
			email
		})
	}

	setPrimaryEmail (venue_id, email) {
		return HTTP.post('/v1/venues/' + venue_id + '/set_primary_email', {
			email
		})
	}

	setAutomatedPerformanaceReport (venue_id, automated_venue_performance_report) {
		return HTTP.post(
			'/v1/venues/' + venue_id + '/automated-venue-performance-report-status',
			{
				automated_venue_performance_report
			}
		)
	}

	unlinkEmail (venue_id, email) {
		return HTTP.post('/v1/venues/' + venue_id + '/unlink_email', {
			email
		})
	}

	updateVenueNotes (params) {
		return HTTP.post('/v1/venues/' + params.venue_id + '/notes', params)
	}

	getVenueCalendarLoginLink (venue_id, email) {
		return HTTP.get(
			'/v1/venues/' +
        venue_id +
        '/get_calendar_login_link?' +
        buildQuery({ email })
		)
	}

	getVenueLeadsByVenueId (venueId) {
		let current = store.getters['venues/getVenueLeads'](venueId)
		return current
	}

	getVenueEmailLogByVenueId (venueId) {
		let current = store.getters['venues/getVenueEmailLogs'](venueId)
		return current
	}

	getVenueRecommendationsByVenueId (venueId) {
		let current = store.getters['venues/getVenueRecommendations'](venueId)
		return current
	}

	formatVenueComponentData (data, current) {
		let selected = []

		for (let row in current) {
			selected.push(current[row].id)
		}
		let temp = current
		for (let row in data) {
			if (selected.indexOf(data[row].id) === -1) {
				temp.push(data[row])
				selected.push(data[row].id)
			}
		}

		return temp
	}
	changeLeadSaleStatus (venueId, leadEventId, data) {
		return HTTP.post(
			'/v1/venues/' +
        venueId +
        '/lead-event/' +
        leadEventId +
        '/lead-sale-status',
			data
		)
	}

	// Get all special offers for a venue
	getSpecialOffers(venueId) {
		return HTTP.get(`/v1/venues/${venueId}/special-offers`);
	}

	// Get all the brochures of a venue
	getVenueBrochures(venueId) {
		return HTTP.get(`/v1/venues/${venueId}/brochures`);
	}
	
    getVenueBrochures(venueId) {
        return HTTP.get(`v1/venues/${venueId}/brochures`);
    }

    async deleteVenueBrochure(brochureId, venueId) {
        return HTTP.delete(`v1/venues/${venueId}/brochures/${brochureId}`);
    }

	// Create a new special offer for a venue
	postSpecialOfferData(venueId, data) {
		return HTTP.post(`/v1/venues/${venueId}/special-offers`, data);
	}
	
	// Get a specific special offer for a venue
	getSpecialOffer(venueId, specialOfferId) {
		return HTTP.get(`/v1/venues/${venueId}/special-offers/${specialOfferId}`);
	}
	
	// Update a specific special offer for a venue
	updateSpecialOffer(venueId, specialOfferId, data) {
		return HTTP.put(`/v1/venues/${venueId}/special-offers/${specialOfferId}`, data);
	}
	
	// Delete a specific special offer for a venue
	deleteSpecialOffer(venueId, specialOfferId) {
		return HTTP.delete(`/v1/venues/${venueId}/special-offers/${specialOfferId}`);
	}
})()
