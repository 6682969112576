<template>
    <div>
        <div class="col-12" v-if="! isLoading && ! isEmptyResultSet('eventCityVenues')">
            <div class="row">
                <VenueListing v-for="(items, index) in filteredVenues['eventCityVenues']"
                    :key="`venue_group_${index}_${items[0]?.venue_id}`"
                    :leadEventId="leadEventId"
                    :rooms="items"
                    :currentCountry="currentCountry"
                    @toggle-drawer="handleToggleDrawer"
                />
            </div>
        </div>
        <div class="col-12" v-else-if="! isLoading && isEmptyResultSet('eventCityVenues') && isEmptyResultSet('otherVenues')">
            <div class="no-match">
                Sorry, no venues match the search criteria. Please refine filters or search term and try again.
            </div>
        </div>

        <div class="col-12 other-city-venues-header" v-if="! isLoading && ! isEmptyResultSet('otherVenues')">
            <div :class="roomScoreClasses(filteredVenues['otherVenues'][0][0]['percentile_score'])">
                Here are some more venues that are further away ..
            </div>
        </div>

        <div class="col-12" v-if="! isLoading && ! isEmptyResultSet('otherVenues')">
            <div class="row">
                <VenueListing v-for="(items, index) in filteredVenues['otherVenues']"
                    :key="`venue_group_${index}_${items[0]?.venue_id}`"
                    :leadEventId="leadEventId"
                    :rooms="items"
                    :currentCountry="currentCountry"
                    @toggle-drawer="handleToggleDrawer" />
                </div>
        </div>
        <div v-if="! isLoading && isEmptyResultSet('all')" class="col-12">
            <div class="no-match">
                <b>Sorry! No venues matched your search criteria.</b>
            </div>
        </div>

		<VenueRoomDetails 
			:visible.sync="drawerVisible" 
			:venue-room-name="selectedVenueRoomName"
			:venue-room-description="selectedVenueRoomDescription"
			:venue-room-images="selectedVenueRoomImages"
            :venue-checklist="selectedVenueChecklist"
		/>
	</div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import recommendationMixin from '@/helpers/recommendationMixin.js';
import KarenService from '@/services/karen.service';
import FilterOptions from "@/views/Pages/Karen/FilterOptions.vue";
import CorrectInvalidData from "@/views/Widgets/KarenV2/CorrectInvalidData.vue";
import VenueListing from '@/views/Widgets/KarenV2/VenueListing';
import VenueRoomDetails from '@/views/Widgets/KarenV2/VenueRoomDetails';
import SendRecommendation from "@/views/Widgets/Recommendations/SendRecommendation";
import { Alert, Button } from 'element-ui';

export default {
	components: {
		RouteBreadCrumb,
		VenueListing,
		[Button.name]: Button,
		[Alert.name]: Alert,
		SendRecommendation,
		FilterOptions,
		VenueRoomDetails,
		CorrectInvalidData
	},
	mixins: [recommendationMixin],
    props: {
        searchedVenueName: null,
        leadEventId: {
            type: Number,
            required: false
        },
        isLoading: {
            type: Boolean,
            default: true
        }
    },
    data() {
		return {
			drawerVisible: false,
			showFilter: false,
			showInitialFilter: false,
			loading: false,
			recommendations: [],
			selectedVenueRoomName: '',
			selectedVenueRoomDescription: '',
			selectedVenueRoomImages: [],
            selectedVenueChecklist: [],
            invalidData: {
                location: false,
                guest_count: false
            },
		};
	},
	methods: {
        async loadEventLocations() {
			let response = await KarenService.getSelectableEventLocations(
				this.$route.params.eventId
			);

			if (response?.data?.selectableLocations) {
                this.cities = response.data.selectableLocations;
			}
		},

		async fetchDefaultSearchCriteria() {
			let response = await KarenService.resetKarenFilterCriteria(this.$route.params.eventId);
			if (response?.data.searchCriteria) {
				this.searchCriteria = response.data.searchCriteria;
			}
		},
		handleToggleDrawer(venue) {
			this.selectedVenueRoomName = venue.room_name;
			this.selectedVenueRoomDescription = venue.room_description;
			this.selectedVenueRoomImages = venue.room_image_gallery;
            this.selectedVenueChecklist = venue.venue_checklist
			this.drawerVisible = true;
		}
	},
	computed: {
        venues() {
            return this.$store.getters['recommendations/getKarenVenuesForListView']('all');
        },
        isEmptyResultSet() {
            return (venueGroupName) => {
                if (venueGroupName == 'all') {
                    return ! Object.keys(this.filteredVenues).length;
                }

                return ! this.filteredVenues[venueGroupName]?.length;
            }
        },
        filteredVenues() {
            if (! this.searchedVenueName || this.searchedVenueName.length < 2) {
                return this.venues;
            }

            const stringToMatch = this.searchedVenueName.toLowerCase();
            return {
                eventCityVenues: this.venues['eventCityVenues']?.filter((venueRooms) => venueRooms[0].venue_name.toLowerCase().includes(stringToMatch)),
                otherVenues: this.venues['otherVenues']?.filter((venueRooms) => venueRooms[0].venue_name.toLowerCase().includes(stringToMatch)),
            };
        },
		lead() {
			return this.$store.getters["leads/getLeadById"](this.$route.params.id);
		},

		currentCountry() {
			let country = !!this.$store.state.country.country ? this.$store.state.country.country : "au";
			let currencySymbol = !!this.$store.state.country.currency ? this.$store.state.country.currency : "$";
			let measurementUnit = !!this.$store.state.country.measurement ? this.$store.state.country.measurement : "km";
			let countrySpecific = {
				country: country,
				currencySymbol: currencySymbol,
				measurementUnit: measurementUnit
			};
			return countrySpecific;
		}
	}
};
</script>

<style lang="scss">

@import '@/assets/sass/core/colors.scss';

.queue-has-item {
    background-color: #24272C;
    color: #F6F9FC;
}
div.card-header > .row > .col-3.page-purpose {
    display: flex;
    align-items: center;
}
div#search_venue {
    display: flex;
    & > span {
        flex-grow: 1;
        margin-right: 3px;
        & > div.form-group {
            margin-bottom: 0px;
        }
    }
}
.venues-alert {
	background-color: #F6F9FC !important;
	padding: 15px;

	.el-alert__content {
		.el-alert__description {
			font-family: 'Effra';
			font-size: 16px;
			margin-top: 0px;
			color: #24272C;

			strong {
				font-weight: 500;
			}
		}
	}
}

.other-city-venues-header {
    font-size: 16px;
    font-weight: 600;
    padding: 20px 15px;
    display: flex;
    color: #24272C;

    > div {
        padding: 15px 20px;
        width: 100%;
        border-radius: 7px;
    }
}

.no-match {
    background: #FFD2D2;
    border-radius: 7px;
    font-size: 16px;
    font-weight: 600;
    padding: 15px 20px;
    color: #24272c;
}
</style>
