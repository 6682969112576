import { RecommendationService } from '../services'
import Vue from 'vue'

export const state = {
    recommendations: [],
    selectedForRecommendation: {},
    recommendableKarenVenuesForListView: {
        eventCityVenues: [],
        otherVenues: []
    },
    recommendableKarenVenuesForMapView: {}
}

export const actions = {
    getRecommendations({ dispatch, commit, getters }, leadEventId) {
        return RecommendationService.getRecommendations(leadEventId)
            .then(recommendations => {
                recommendations = recommendations.data;
                commit('UpdateLeadEventRecommendations', {
                    recommendations,
                    leadEventId
                })
            })
            .catch(error => {
                dispatch('alert/error', error, { root: true })
            })
    },
    sendRecommendation({ dispatch }, data) {
        return RecommendationService.sendRecommendation(
            data.leadEventId,
            data.venues,
            data.template,
            data.subject,
            data.portalLink
        )
            .then(response => {
                if (response?.data?.success === true) {
                    dispatch('alert/success', 'Recommendation email sent successfully.', {
                        root: true
                    })
                } else {
                    dispatch(
                        'alert/error',
                        'An error occurred while sending the email.',
                        { root: true }
                    )
                }
            })
            .catch(error => {
                dispatch('alert/error', error, { root: true })
            })
    }
}
export const mutations = {
    updateKarenVenuesForListView(state, venueRooms) {
        state.recommendableKarenVenuesForListView.eventCityVenues = venueRooms['eventCityVenues'] ?? [];
        state.recommendableKarenVenuesForListView.otherVenues = venueRooms['otherVenues'] ?? [];

    },
    updateKarenVenuesForMapView(state, venueRooms) {
        state.recommendableKarenVenuesForMapView = venueRooms;
    },
    updateKarenVenueInMapView(state, payload) {
        Vue.set(state.recommendableKarenVenuesForMapView, payload.venueId, payload.venueRooms);
    },
    UpdateLeadEventRecommendations(state, data) {
        Vue.set(state.recommendations, data.leadEventId, data.recommendations)
    },
    updateSelectedForRecommendation(state, {leadEventId, rooms}) {
        if (! leadEventId || ! rooms) {
            return;
        }

        Vue.set(state.selectedForRecommendation, leadEventId, rooms);
    }
}
export const getters = {
    getRecommendationsByLeadEventId: state => leadEventId => {
        return state.recommendations[leadEventId] ?? [];
    },
    getSelectedVenueRoomsForRecommendation: state => leadEventId => {
        return state.selectedForRecommendation[leadEventId] ?? [];
    },
    getKarenVenuesForListView: state => locationType => {
        if (! ['all', 'eventCityVenues', 'otherVenues'].includes(locationType)) {
            return [];
        }

        if (locationType == 'all') {
            return state.recommendableKarenVenuesForListView;
        } else {
            return state.recommendableKarenVenuesForListView['locationType'] ?? [];
        }
    },
    getKarenVenuesForMapView: state => () => {
        return state.recommendableKarenVenuesForMapView
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
