import { HTTP } from '../services'

export default new (class LeadEventService {
  getLeadEventByLeadId (leadId) {
    return HTTP.get('/v1/leads/' + leadId + '/lead-events')
  }

  updateLeadEventData (leadEventId, data) {
    return HTTP.put('/v1/lead-event/' + leadEventId, data)
  }

  crateLeadEvent (leadId, data) {
    return HTTP.post('/v1/leads/' + leadId + '/lead-event', data)
  }

  mergeEvents (primaryLeadEvent, secondaryLeadEvent) {
    return HTTP.put(
      '/v1/lead-event/' +
        primaryLeadEvent.id +
        '/merge/' +
        secondaryLeadEvent.id
    )
  }

  getLeadEventData (leadEventId) {
    return HTTP.get('/v1/lead-event/' + leadEventId)
  }

  timeline (leadEventId, timezone) {
    return HTTP.get(
      '/v1/lead-event/' + leadEventId + '/timeline?timezone=' + timezone
    )
  }

  requeueEvents (leadEventId, tracker_id, type) {
    return HTTP.post('/v1/lead-event/' + leadEventId + '/requeue_events', {
      tracker_id,
      type
    })
  }

  syncFromHubspot (leadId) {
    return HTTP.post('/v1/leads/' + leadId + '/sync-deals-from-hubspot')
  }

  setVenueAvailability (leadEventId, venueId, isAvailable) {
    return HTTP.post(
      '/v1/lead-event/' + leadEventId + '/venue/' + venueId + '/availability',
      { is_available: isAvailable }
    )
  }

  uploadQuote (leadEventId, form) {
    return HTTP.post('/v1/lead-event/' + leadEventId + '/upload-quote', form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  removeQuote (leadEventId, venueId) {
    return HTTP.delete('/v1/lead-event/' + leadEventId + '/quote/' + venueId)
  }

  getQuoteDownloadUrl (leadEventId, venueId) {
    return HTTP.get(
      '/v1/lead-event/' + leadEventId + '/quote/' + venueId + '/download-url'
    )
  }

  saveQuoteLink (leadEventId, venueId, link) {
    return HTTP.post(
      '/v1/lead-event/' + leadEventId + '/quote/' + venueId + '/link',
      { quote_link: link }
    )
  }

  // Update deal pipeline stage of a lead event.
  updateDealStage (leadEventId, dealStageId) {
    return HTTP.post('/v1/lead-event/' + leadEventId + '/update-deal-stage', {
      pipeline_stage_id: dealStageId
    })
  }

  // Send venue brochures via email
  sendBrochureRequestEmail (leadEventId, data) {
    return HTTP.post(
      `/v1/lead-event/` + leadEventId + `/send-venue-brochure`,
      data
    )
  }
})()
