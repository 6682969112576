import Vue from 'vue';
import VueRouter from 'vue-router';
import NProgress from 'nprogress';
import '../../node_modules/nprogress/nprogress.css';
import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import SubContentLayout from '@/views/Layout/SubContentLayout.vue';
import AuthLayout from '@/views/Layout/AuthLayout.vue';
import NotFound from '@/views/Layout/NotFoundPage.vue';
import InspectionLayout from '@/views/Layout/InspectionLayout.vue';
import { store } from '@/store';
import { AuthService } from '../services';
import EventVenueRecommendation from '@/views/Pages/Leads/EventVenueRecommendation.vue';
Vue.use(VueRouter);

// Pages
const Login = () => import('@/views/Pages/Login.vue'); // todo 3 => You can name LoginPage - it will be consistent with layout naming
const Dashboard = () => import('@/views/Pages/Dashboard.vue');
const InspectionReports = () => import('@/views/Pages/Reports/InspectionReports.vue');
const VenueSpecialistReports = () => import('@/views/Pages/Reports/VenueSpecialistReports.vue');
const LeadingIndicatorReport = () => import('@/views/Pages/Reports/LeadingIndicatorReport.vue');
const SingleLeadingIndicatorReport = () => import('@/views/Pages/Reports/SingleLeadingIndicatorReport.vue');
const VenuesSalesHitList = () => import('@/views/Pages/Reports/VenuesSalesHitList.vue');
const VenuePerformanceReport = () => import('@/views/Pages/Reports/VenuePerformance.vue');
const VenueAuditLog = () => import('@/views/Pages/Reports/VenueAuditLog.vue');
const Leads = () => import('@/views/Pages/Leads/Leads.vue');
const DistributionRules = () => import('@/views/Pages/Leads/DistributionRules.vue');
const LeadDistribution = () => import('@/views/Pages/Leads/LeadDistribution.vue');
const LeadDuplicates = () => import('@/views/Pages/Leads/LeadDuplicates.vue');
const LeadDetails = () => import('@/views/Pages/Leads/LeadDetails.vue');
const LeadShortlist = () => import('@/views/Pages/Leads/LeadShortlist.vue');
const LeadEventRecommendation = () => import('@/views/Pages/LeadEvents/LeadEventRecommendation.vue');
const Karen = () => import('@/views/Pages/LeadEvents/Karen.vue');
const KarenV2Listing = () => import('@/views/Pages/Leads/KarenV2Listing.vue');

const Venues = () => import('@/views/Pages/Venues/Venues.vue');
const VenueDetails = () => import('@/views/Pages/Venues/VenueDetails.vue');
const Inspections = () => import('@/views/Pages/Inspections/Inspections.vue');
const InspectionNew = () => import('@/views/Pages/Inspections/InspectionNew.vue');
const InspectionRequest = () => import('@/views/Pages/InspectionRequest.vue');
const CreateLead = () => import('@/views/Pages/Leads/CreateLead.vue');

const Users = () => import('@/views/Pages/Users/Users.vue');
const UserDetails = () => import('@/views/Pages/Users/UserDetails.vue');
const CreateUser = () => import('@/views/Pages/Users/CreateUser.vue');
const VenueReportValues = () => import('@/views/Pages/Reports/VenueReportValues.vue');

const EnquiryAudits = () => import('@/views/Pages/Reports/EnquiryAudits.vue');

const Settings = () => import('@/views/Pages/Settings/Settings.vue');
const KarenSettings = () => import('@/views/Pages/Settings/KarenSettings.vue');

const routes = [
	{
		path: '/login',
		name: 'Login',
		component: AuthLayout,
		meta: { public: true, name: 'Login' },
		children: [
			{
				path: '/login',
				component: Login
			}
		]
	},
	{
		path: '/',
		component: DashboardLayout,
		redirect: '/dashboard',
		name: 'Dashboard',
		meta: { name: 'Dashboard' },
		children: [
			{
				path: 'dashboard',
				name: 'DashboardChild',
				meta: { name: 'Dashboard' },
				component: Dashboard
			}
		]
	},
	{
		path: '/leads/',
		component: DashboardLayout,
		redirect: '/leads/all',
		name: 'Leads',
		meta: { name: 'Leads' },
		children: [
			{
				path: 'all',
				name: 'AllLeads',
				meta: { name: 'All Leads' },
				component: Leads
			},
			{
				path: 'lead-distribution',
				name: 'LeadDistribution',
				meta: { name: 'Lead Distribution' },
				component: LeadDistribution
			},
			{
				path: 'create',
				name: 'NewLead',
				meta: { name: 'New Lead' },
				component: CreateLead
			},
			{
				path: 'distribution-rules',
				name: 'DistributionRules',
				meta: { name: 'Distribution Rules' },
				component: DistributionRules
			},
			{
				path: 'lead-duplicates',
				name: 'LeadDuplicates',
				meta: { name: 'Lead Duplicates' },
				component: LeadDuplicates
			},
			{
				path: ':id',
				name: 'LeadDetails',
				meta: { name: 'Lead Details' },
				component: LeadDetails
			},
			{
				path: ':id/event/',
				redirect: '/leads/all',
				component: SubContentLayout,
				name: 'Lead Details',
				meta: { name: 'Lead Details', breadcrumb_path: '/leads/:id' },
				children: [
					{
						path: ':eventId/inspections',
						name: 'LeadInspections',
						meta: { name: 'Event Inspections' },
						component: LeadShortlist
					},
					{
						path: ':eventId/recommendation',
						name: 'LeadEventRecommendation',
						meta: { name: 'Event Recommendations' },
						component: LeadEventRecommendation
					},
					{
						path: ':eventId/karen',
						name: 'Karen',
						meta: { name: 'Karen' },
						component: Karen
					},
					{
						path: ':eventId/karenV2',
						name: 'KarenV2Listing',
						meta: { name: 'KAREN' },
                        component: EventVenueRecommendation
                    },
					{
						path: ':eventId/inspections/new/',
						name: 'CreateNewInspection',
						meta: { name: 'Create New Inspection' },
						component: InspectionNew
					}
				]
			}
		]
	},
	{
		path: '/venues/',
		component: DashboardLayout,
		redirect: '/venues/all',
		name: 'Venues',
		meta: { name: 'Venues' },
		children: [
			{
				path: 'all',
				name: 'AllVenues',
				meta: { name: 'All Venues' },
				component: Venues
			},
			{
				path: ':id',
				name: 'VenueDetails',
				meta: { name: 'Venue Details' },
				component: VenueDetails
			}
		]
	},
	{
		path: '/inspections/',
		component: DashboardLayout,
		name: 'Inspections',
		redirect: '/inspections/inspection-requests',
		meta: { name: 'Inspections' },
		children: [
			{
				path: 'inspection-requests',
				name: 'All',
				meta: { name: 'All Inspections' },
				component: Inspections
			}
		]
	},
	{
		path: '/reports/',
		name: 'VenuePerformanceReport',
		component: VenuePerformanceReport,
		meta: { public: true, name: 'VenuePerformanceReport' }
	},
	{
		path: '/reports/',
		component: DashboardLayout,
		name: 'Reports',
		meta: { name: 'Reports', teamlead: true },
		children: [
			{
				path: 'venue-report-values',
				name: 'VenueReportValues',
				component: VenueReportValues,
				meta: { name: 'Venue Report Values', admin: true }
			},
			{
				path: 'inspections',
				name: 'InspectionsReports',
				meta: { name: 'InspectionsReport', teamlead: true },
				component: InspectionReports
			},
			{
				path: 'venue-specialist',
				name: 'VenueSpecialistReport',
				meta: { name: 'VenueSpecialistReport', teamlead: true },
				component: VenueSpecialistReports
			},
			{
				path: 'au/performance',
				name: 'LeadingIndicatorReportAU',
				meta: { name: 'LeadingIndicatorReportAU', teamlead: true, sales: true, country: 'au' },
				component: LeadingIndicatorReport
			},
			{
				path: 'uk/performance',
				name: 'LeadingIndicatorReportUK',
				meta: { name: 'LeadingIndicatorReportUK', teamlead: true, sales: true, country: 'uk' },
				component: LeadingIndicatorReport
			},
			{
				path: 'uk/performance/:venuespecialist',
				name: 'SingleLeadingIndicatorReportUK',
				meta: { name: 'SingleLeadingIndicatorReportUK', teamlead: true, sales: true, country: 'uk' },
				component: SingleLeadingIndicatorReport
			},
			{
				path: 'au/performance/:venuespecialist',
				name: 'SingleLeadingIndicatorReportAU',
				meta: { name: 'SingleLeadingIndicatorReportAU', teamlead: true, sales: true, country: 'au' },
				component: SingleLeadingIndicatorReport
			},
			{
				path: 'venues-sales-hit-list',
				name: 'VenuesSalesHitList',
				meta: { name: 'Venues Sales Hit List', teamlead: true },
				component: VenuesSalesHitList
			},
			{
				path: 'au/venue-audit-log',
				name: 'Venue Audit Log AU',
				meta: { name: 'Venue Audit Log (AU)', teamlead: true, country: 'au' },
				component: VenueAuditLog
			},
			{
				path: 'uk/venue-audit-log',
				name: 'Venue Audit Log UK',
				meta: { name: 'Venue Audit Log (UK)', teamlead: true, country: 'uk' },
				component: VenueAuditLog
			},
			{
				path: 'enquiry-audits',
				name: 'Enquiry Audits',
				meta: { name: 'Enquiry Audits', teamlead: true },
				component: EnquiryAudits
			}
		]
	},
	{
		path: '/inspection-request/',
		component: InspectionLayout,
		children: [
			{
				path: ':id',
				name: 'InspectionRequest',
				component: InspectionRequest,
				meta: { public: true, name: 'Inspection Request' }
			}
		]
	},
	{
		path: '/users/',
		component: DashboardLayout,
		name: 'Users',
		meta: { name: 'Users', teamlead: true },
		redirect: '/users/all',
		children: [
			{
				path: 'all',
				name: 'AllUsers',
				meta: { name: 'All Users', teamlead: true },
				component: Users
			},
			{
				path: 'new',
				name: 'CreateNewUser',
				meta: { name: 'Create New User', teamlead: true },
				component: CreateUser
			},
			{
				path: ':id',
				name: 'UserDetails',
				meta: { name: 'User Details' , teamlead: true },
				component: UserDetails,
			},
		],
	},
	{
		path: '/settings',
		component: DashboardLayout,
		children: [
			{
				path: '', // This route won't be directly accessible
				redirect: { name: 'KarenSettings' },
				component: Settings,
				name: 'Settings',
			},
			{
				path: 'karen',
				component: KarenSettings,
				name: 'KarenSettings',
			},
		],
	},
	{
		path: '*',
		component: AuthLayout,
		name: 'notFound',
		meta: { public: true, name: 'Not Found' },
		children: [
			{
				path: '*',
				component: NotFound
			}
		]
	}
];

// configure router
export const router = new VueRouter({
	mode: 'history',
	routes, // short for routes: routes
	linkActiveClass: 'active',
	scrollBehavior: (to, from, savedPosition) => {
		if (savedPosition) {
			return savedPosition;
		}
		if (to.hash) {
			return { selector: to.hash };
		}
		return { x: 0, y: 0 };
	}
});
let refreshedRetries = 0;

router.beforeEach((to, from, next) => {
	if (to.name) {
		NProgress.start();
	}

	const loggedIn = localStorage.getItem('user');
	if (to.path === '/login' && loggedIn) {
		// Logged in and accessing login page, then redirect to Dashboard
		if (to.query.redirect) {
			next({ path: to.query.redirect });
		} else {
			next({ name: 'Dashboard' });
		}
	} else if (to.matched.some((record) => record.meta.public)) {
		// Public page, allow anyway
		next();
	} else if (to.matched.some((record) => record.meta.admin && !store.getters['auth/isAdministrator'])) {
		next({ name: 'Dashboard' });
	} else if (
		(to.matched.some((record) => record.meta.teamlead && !store.getters['auth/isTeamlead'])) &&
		(to.matched.some((record) => record.meta.sales && !store.getters['auth/isSales']))
	) {
		next({ name: 'Dashboard' });
	} else {
		if (!loggedIn) {
			// Redirect login if not login page
			console.info('NOT LOGGED IN');
			return redirectToLogin();
		} else {
			refreshedRetries += 1;
			// Session found, check with validity of user before accessing the page
			return store
				.dispatch('auth/loginRefresh')
				.then((validUser) => {
					refreshedRetries = 0;
					// Then continue if the token still represents a valid user,
					// otherwise redirect to login.
					if (!validUser) {
						// console.info('Login refresh succes');
						// console.log(validUser);
						AuthService.logout();
					}
					return validUser ? next() : redirectToLogin();
				})
				.catch(function (err) {
					console.info('Login refresh with error. retry ' + refreshedRetries);
					console.error(err);
					return redirectToLogin();
				});
		}
	}

	next();

	function redirectToLogin() {
		if (refreshedRetries > 5) {
			store.dispatch('auth/logout');
		}
		next({
			path: '/login',
			query: { redirect: to.fullPath }
		});
	}
});

router.afterEach((to, from) => {
	NProgress.done();
});
