<template>
    <div v-loading="venues?.loading" class="venues-list">
        <venues-map-view @select="addToQueue" @remove="removeFromQueue" :selected="recommendations" :venues="venues"
            addToListLabel="Add To Queue" :shouldSelectSpaces="true"></venues-map-view>
    </div>
</template>
<script>
    import VenuesMapView from "@/views/Widgets/Venues/VenuesMapView.vue";
import { required } from "vee-validate/dist/rules";
    export default {
        components: {
            VenuesMapView,
        },
        props: {
            venues: {
                type: Array,
            },
            leadEventId: {
                required: true
            },
            recommendations: {
                type: Array,
                default: () => []
            },
        },
        methods: {
            addToQueue(venue) {
                this.recommendations.push(venue);
                this.$emit('update:recommendations', this.recommendations);
                console.log('add to queue');
                this.$emit('add-to-queue', venue); // required to update the vuex store
            },
            removeFromQueue(venue) {
                var recommendations = this.recommendations.filter(function (item) {
                    return parseInt(item.id) !== parseInt(venue.id);
                });
                this.$emit('update:recommendations', recommendations);
                this.$emit('remove-from-queue', venue); // required to update the vuex store

            },
        },
        computed: {
            queuedVenueRooms() {
                return this.$store.getters['recommendations/getSelectedVenueRoomsForRecommendation'](this.leadEventId);
            }
        }
    };

</script>