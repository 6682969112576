<template>
    <div>
        <div id="filters_and_queue" class="col-3">
            <form>
                <h6 class="heading-small text-muted mb-4">Search</h6>
                <div class="row">
                    <div class="col-lg-12">
                        <base-input class="custom-search input-group-lg input-group-flush mb-0"
                            prepend-icon="fas fa-search" placeholder="Search..." id="search_input" type="search"
                            v-model="searchQuery" />
                    </div>
                </div>
                <hr />
            </form>
            <hr />
            <send-recommendation :lead-event-id="leadEventId"
                queued-venues-container-id="queued_venues_map_view"
            />
        </div>

        <div class="col-9" id="venue_list">
            <h6 class="heading-small text-muted mb-4">Venue List</h6>
            <div class="venues-list">
                <MapViewForKarenV2
                    @select="addToQueueInStore"
                    @remove="removeFromQueueInStore"
                    :selected="queuedRooms"
                    :venues="filteredVenues"
                    addToListLabel="Add To Queue"
                    :shouldSelectSpaces="true"
                ></MapViewForKarenV2>
            </div>
        </div>
    </div>
</template>

<script>
    import leadFilters from '@/components/Leads/leadFilters.vue';
    import VenueRecommendedMap from './VenueRecommendedMap.vue';
    import Collapse from '@/components/Collapse/Collapse.vue';
    import CollapseItem from '@/components/Collapse/CollapseItem.vue';
    import SendRecommendation from './SendRecommendation.vue';
    import MapViewForKarenV2 from '../Venues/MapViewForKarenV2.vue';
    import { Select, Option } from 'element-ui';
    import recommendationMixin from '@/helpers/recommendationMixin';

    export default {
        props: {
            venues: {
                required: true,
                default: () => []
            },
            leadEventId: {
                required: true
            },
            queuedRooms: {
                required: true,
                default: () => []
            }
        },
        mixins: [recommendationMixin],
        data() {
            return {
                searchQuery: "",
                searchCountry: "",
                filteredVenues: this.venues
            }
        },
        components: {
            leadFilters,
            MapView: VenueRecommendedMap,
            Collapse,
            CollapseItem,
            SendRecommendation,
            MapViewForKarenV2,
            [Select.name]: Select,
            [Option.name]: Option,
        },
        computed: {
            roomsSelectedForRecommendation() {
                return this.$store.getters['recommendations/getSelectedVenueRoomsForRecommendation'](this.leadEventId);
            },
        },
        watch: {
            venues: function(newVal) {
                if (newVal) {
                    this.filteredVenues = newVal;
                }
            },
            searchQuery: function(newSearchStr) {
                const country = this.searchCountry;
                this.applyFilters(newSearchStr, country);
            },
            searchCountry: function(newCountryValue, oldVal) {
                if (newCountryValue?.length && newCountryValue != oldVal) {
                    const searchStr = this.searchQuery;
                    this.applyFilters(searchStr, newCountryValue);
                }
            }
        },
        methods: {
            applyCountryFilter(country) {
                if (country?.length) {
                    return this.venues.filter(venueRooms => venueRooms[0].country?.toLowerCase() == countryLower);
                }

                return this.venues;
            },
            applyStringFilter(str, venues) {
                if (! str?.length || str?.length <= 2) {
                    this.filteredVenues = venues;
                    return;
                }

                this.filteredVenues = venues?.filter(venueRooms => venueRooms[0].venue_name?.toLowerCase().includes(str));
            },

            applyFilters(searchStr, country) {
                const countryLower = country?.toLowerCase();
                const searchStrLower = searchStr?.toLowerCase();

                let result = this.applyCountryFilter(countryLower);
                this.applyStringFilter(searchStrLower, result);
            },
        }
    }
</script>