<template>
	<div class="col-12 col-sm-6 col-md-4">
		<div class="card venue-listing relative" v-if="displayedRoom"
            :class="{ 'already-recommended': isRoomAlreadyRecommended }"
        >
            <div v-if="isRoomAlreadyRecommended" class="card-overlay"></div>
			<div class="card-header">
				<div class="col card-header-content" @click="roomSidebar(displayedRoom)">
					<div class="row">
						<div class="col-10">
							<span class="venue-location">
								<i class="fal fa-map-marker"></i>
                                {{ displayedRoom?.location_string }} ({{ displayedRoom.distance }} {{ currentCountry.measurementUnit }})
							</span>
						</div>
						<div class="col-2 pr-0">
							<div v-if="selectedRoomId !== null" :class="[roomScoreClasses(displayedRoom?.percentile_score), { 'relative': displayedRoom?.is_high_conversion_venue }]">
								<span v-if="selectedRoomId !== null">{{ displayedRoom.percentile_score }}</span>
                                <i v-if="displayedRoom.is_high_conversion_venue" class="high-converting fas fa-star"></i>
                                <span v-if="displayedRoom.is_high_conversion_venue" class="icon-tooltip">High booking rate</span>
							</div>
						</div>
					</div>
					<div class="row venue-title">
						<div class="pl-0 d-flex flex-grow-1">
							<h6 class="card-subtitle mb-2 text-muted">{{ displayedRoom.room_name }}</h6>
							<h5 class="card-title">{{ displayedRoom.venue_name }}</h5>
						</div>
                        <div class="bg-info rounded-start rounded-left font-weight-bold text-dark text-right px-3" v-if="isRoomAlreadyRecommended">
                            Recommendation sent
                        </div>
					</div>
				</div>
				<div class="image-header-overlay"></div>
				<div class="image-header" :style="{ 'background-image': 'url(' + displayedRoom.image_url + ')' }"></div>
			</div>

            <el-select v-model="selectedRoomId" class="room-selector">
                <el-option
                    v-for="(room, index) in rooms"
                    :key="`choose_room_${index}_${room.room_id}`"
                    :label="room.room_name"
                    :value="room.room_id"
                >
                    <span v-if="room.room_name != 'Entire Venue'" style="float: left;">{{ room.room_name }}</span>
                    <span v-else class="entire-venue">---  {{ room.room_name }}  ---</span>

                    <span v-if="room.room_name != 'Entire Venue'" style="float: right; font-size: 12px; padding: 0 8px; border-radius: 4px; color: white; height: fit-content;"
                        :class="roomScoreClasses(room.percentile_score)"
                    >{{ room.percentile_score }}</span>
                </el-option>
            </el-select>
			<div class="card-body">
				<div class="card-body-content">
					<div class="row">
						<div class="col-8 text-left">
							<div class="row venue-detail">
                                <div class="col">
                                    <i class="fal fa-plate-utensils fa-venue-detail"></i>
                                    {{ displayedRoom.dinner_and_dance_min_capacity }} - {{ displayedRoom.dinner_and_dance_max_capacity }}
                                </div>
                                <div class="col">
                                    <i class="fal fa-martini-glass-citrus fa-venue-detail"></i>
                                    {{ displayedRoom.standing_min_capacity }} - {{ displayedRoom.standing_max_capacity }}
                                </div>
							</div>
                            <div class="row mt-2 venue-detail">
                                <div class="col">
                                    <i class="fal fa-male fa-venue-detail"></i>
                                    {{ currentCountry.currencySymbol }}{{ displayedRoom.cost_per_person }}/PP
                                </div>
                                <div class="col">
                                    <i class="fal fa-usd-circle fa-venue-detail"></i>
                                    {{ currentCountry.currencySymbol }}{{ displayedRoom.min_cost_for_event }}+
                                </div>
                            </div>
						</div>
                        <div class="col-4 text-right">
							<div class="row venue-detail">
                                <div class="col pl-0">
                                    <i class="fal fa-cutlery fa-venue-detail"></i>
                                    {{ displayedRoom.external_catering_permitted ? 'YES' : 'NO' }}
                                </div>
							</div>
						</div>
					</div>
					<div class="row card-usps">
						<div class="col">
                            {{ displayedRoom.room_description }}
						</div>
					</div>
				</div>
				<div class="row card-actions">
					<button :class="['col pl-0 text-center action-accept', { 'active': selected }]"
						:disabled="isRoomAlreadyRecommended" @click="addOrRemoveRoomFromRecommendedList">
						<i class="fa fa-check fa-action-item"></i>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Notification } from 'element-ui';
import { Select, Option } from 'element-ui';
import recommendationMixin from '@/helpers/recommendationMixin';
import { maxQueueLength } from '@/services/recommendation.service';

export default {
	props: {
        leadEventId: {
            type: Number,
            required: false
        },
        rooms: {
            type: Array,
            required: true
        },
		currentCountry: {
			type: Object,
			required: true
		},
	},
	mixins: [recommendationMixin],

    components: {
        [Select.name]: Select,
        [Option.name]: Option
    },
    mounted: function () {
        // The first room is always the fake room called Entire Venue.
        // On mount, ideally we do not want to display that room on the card since it is missing some data. So we pick the next room
        // from the list of rooms of this venue if there are multiple rooms. Otherwise we swallow the pill.
        this.displayedRoom = this.rooms.length > 1 ? this.rooms[1] : this.rooms[0];

        // On mount, the default selection on the room dropdown will be the 2nd room for the reason mentioned above,
        // If there is just one room, we set the selectedRoomId to be 'null'. This is because, when there is just one
        // room in a venue, that is the fake room called 'Entire Venue' which we added from the server side. That room does not have any id('null').
        this.selectedRoomId = this.rooms.length > 1 ? this.rooms[1].room_id : null;
    },
    data() {
        return {
            displayedRoom: null,
            selectedRoomId: -1
        }
    },
	computed: {
		formattedCountry() {
			return this.currentCountry;
		},
		miniumCost() {
			return function (venue) {
				return venue.cost_per_person * venue.min_capacity == 0
					? venue.min_capacity + ' per person'
					: venue.cost_per_person * venue.min_capacity;
			};
		},
		selected() {
            if (this.isEntireVenueSelectedForRecommendation) {
                const index = this.roomsSelectedForRecommendation.findIndex(room => {
                    return room.room_name == 'Entire Venue' && room.venue_id == this.rooms[0].venue_id;
                });

                return index !== -1;
            }

			const index = this.roomsSelectedForRecommendation.findIndex(room => room.room_id == this.displayedRoom.room_id);
			return index !== -1;
		},
        isEntireVenueSelectedForRecommendation() {
            // selectedRoomId will be null if user selects the 'Entire Venue' from the dropdown.
            // Entire Venue is a fake room and does not have any id.
            return this.selectedRoomId === null;
        },
        isRoomAlreadyRecommended () {
            // selectedRoomId will be null if user selects the Entire Venue from the dropdown.
            // Entire Venue is a fake room and does not have any id. And for each venue, the first
            // element in the rooms array is this fake room
            return this.selectedRoomId === null 
                ? this.rooms[0].has_been_recommended_already
                : this.displayedRoom.has_been_recommended_already
        }
	},
    watch: {
        selectedRoomId: function(newVal) {
            // null means the fake 'Entire Venue' room was selected by the user from the room selector.
            // Ideally we do not want to display that room on the card since it is missing many information.
            if (newVal !== null) {
                const selectedRoomIndex = this.rooms.findIndex((room) => room.room_id == newVal);
                this.displayedRoom = this.rooms[selectedRoomIndex];
            }
        },
        rooms: {
            handler: function (newVal, oldVal) {
                const vm = this;
                if (newVal?.length) {
                    const idx = newVal.findIndex((room) => room.room_id == vm.displayedRoom?.room_id);
                    this.displayedRoom = newVal[idx];
                }
            },
            deep: true,
            immediate: true
        }
    },
	methods: {
        removeRoomFromShortList() {
            let temp = this.roomsSelectedForRecommendation;

            let index = -1;
            if (this.isEntireVenueSelectedForRecommendation) {
                index = temp.findIndex(room => room.room_name == 'Entire Venue' && room.venue_id == this.rooms[0].venue_id);
            } else {
                index = temp.findIndex(room => room.room_id == this.displayedRoom.room_id);
            }

            if (index > -1) {
                temp.splice(index, 1);
            }

            this.$store.commit('recommendations/updateSelectedForRecommendation', {
                leadEventId: this.leadEventId,
                rooms: temp,
            });

            return;
        },
		addOrRemoveRoomFromRecommendedList() {
            // If already selected for recommendation, remove it.
            if (this.selected) {
                return this.removeRoomFromShortList();
            }

            // Cannot select more than 6 at once
			if (this.roomsSelectedForRecommendation.length >= maxQueueLength) {
				Notification.error({
					title: 'Recommendation Limit Reached',
					message: `You have reached the maximum limit of ${maxQueueLength} recommended venues/rooms. Please review your selections.`,
					showClose: true
				});

				return;
			}

            let selectedRoom = this.selectedRoomId === null ? this.rooms[0] : this.displayedRoom;
            let temp = this.roomsSelectedForRecommendation;
            temp.push(selectedRoom);
            this.$store.commit('recommendations/updateSelectedForRecommendation', {
                leadEventId: this.leadEventId,
                rooms: temp,
            });
		},
		roomSidebar(venue) {
            if (venue) {
                this.$emit('toggle-drawer', venue);
            }
		}
	}
};
</script>

<style scoped lang="scss">
    @import "@/assets/sass/custom/_karenv2card";
</style>

<style scoped>
    .el-select {
        margin: 10px 20px;

    }

    .el-select >>> .el-select__caret {
        color: black;
    }

    .el-select >>> .el-input__inner {
        font-weight: 600;
    }

    .el-select-dropdown.el-popper li.el-select-dropdown__item {
        font-weight: 600;
    }

    .el-select-dropdown.el-popper li.el-select-dropdown__item:not(.selected) {
        color: black;
    }

    .el-select-dropdown__item {
        margin-bottom: 10px;
    }
</style>