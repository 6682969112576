<template>
    <div :class="shouldSelectSpaces ? 'venues-list venue-map-view-with-spaces' : 'venues-list'">
        <GmapMap :center="center" ref="mapRef" :zoom="12" map-type-id="roadmap" :options="options"
            style="width: 100%; height: 600px">
            <GmapMarker :key="index" v-for="(venueRooms, index) in venues"
                :position="getCoords(venueRooms)"
                :clickable="true"
                :icon="getMarker(venueRooms)"
                @click="openInfoWindow(venueRooms)"
            />

            <gmap-info-window v-if="!!selectedLocation" :position="infoWindowPos" :opened="infoBoxOpen" :options="{
                pixelOffset: {
                    width: 0,
                    height: -35,
                },
            }" @closeclick="infoBoxOpen = false">
                <div class="info-window">
                    <div class="img-wrapper">
                        <img :src="selectedRoomThumbnail()" />
                    </div>
                    <div class="info-window-body">
                        <h3 class="truncate">{{ selectedLocation.name }}</h3>
                        <h4 class="m-b-2">
                            <i class="fas fa-map-pin"></i> {{ getLocation(selectedLocation.rooms ?? {}) }}
                        </h4>
                        <el-select v-if="shouldSelectSpaces" @change="selectASpaceForVenue"
                            v-model="isSpaceSelected[selectedLocation.id]" placeholder="Select A Space" size="mini"
                        >
                            <el-option v-for="(item, key) in selectedLocation.rooms"
                                :key="key"
                                :label="key"
                                :value="constructValueForSelectRoomDropDown(selectedLocation.id, key, item.room_id)">
                            </el-option>
                            <!-- selectedLocation.id + ':' + key + ':' + item.room_id -->
                        </el-select>
                        <button :class="`btn btn-block btn-outline-${addBtnClass(selectedLocation)}`"
                            @click="updateSelected(selectedLocation)">
                            {{ selectedLocation.btnText }}
                        </button>
                    </div>
                </div>
            </gmap-info-window>
        </GmapMap>
    </div>
</template>

<script>
    import { Select, Option } from 'element-ui';

    export default {
        components: {
            [Select.name]: Select,
            [Option.name]: Option,
        },
        props: {
            venues: {
                type: Array,
            },
            selected: {
                type: Array,
            },
            addToListLabel: {
                type: String,
            },
            shouldSelectSpaces: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                isSpaceSelected: {},
                spacesSelected: {},
                googlemap: null,
                infoWindowPos: null,
                infoOptions: {
                    content: "",
                    pixelOffset: {
                        width: 0,
                        height: -35,
                    },
                },
                center: { lat: -37.8136, lng: 144.9631 },
                selectedLocation: {
                    id: null,
                    name: null,
                    room_id: null,
                    space: null,
                    thumbNail: null,
                    rooms: [],
                    selected: false,
                    btnText: null
                },
                infoBoxOpen: false,
                options: {
                    zoom: 200,
                    styles: [
                        {
                            featureType: "administrative",
                            elementType: "geometry",
                            stylers: [{ visibility: "off" }],
                        },
                        {
                            featureType: "poi",
                            stylers: [{ visibility: "off" }],
                        },
                        {
                            featureType: "road",
                            elementType: "labels.icon",
                            stylers: [{ visibility: "off" }],
                        },
                        {
                            featureType: "transit",
                            stylers: [{ visibility: "off" }],
                        },
                    ],
                },

            };
        },
        mounted() {
            // At this point, the child GmapMap has been mounted, but
            // its map has not been initialized.
            // Therefore we need to write mapRef.$mapPromise.then(() => ...)

            this.$refs.mapRef.$mapPromise.then((map) => {
                const bounds = new google.maps.LatLngBounds();
                for (let venueRoom of this.selected) {
                    venueRoom.position = {
                        lat: parseFloat(this.getCoords([venueRoom]).lat),
                        lng: parseFloat(this.getCoords([venueRoom]).lng),
                    };
                    bounds.extend(venueRoom.position);
                    this.center = bounds.getCenter();
                }
                map.fitBounds(bounds);
                map.panTo(this.center);
            });
        },
        watch: {
            selected: function (newVal, oldVal) {
                newVal.forEach(venueRoom => {
                    let venueId = venueRoom.venue_id;
                    /** @todo Need to cater for multiple room on a venue */
                    if (! Object.keys(this.spacesSelected).includes(venueId)) {
                        this.spacesSelected[venueId] = venueRoom.room_name;
                    }

                    if (! Object.keys(this.isSpaceSelected).includes(venueId)) {
                        this.isSpaceSelected[venueId] = this.constructValueForSelectRoomDropDown(venueId, venueRoom.room_name, venueRoom.room_id);
                    }
                });

                // Now remove any space that was previously selected, but now removed
                const allSelectedVenueIds = this.selected.map(venueRoom => venueRoom.venue_id);
                for (const venueId in this.spacesSelected) {
                    if (! allSelectedVenueIds.includes(parseInt(venueId))) {
                        this.$delete(this.spacesSelected, venueId);
                        this.$delete(this.isSpaceSelected, venueId);
                    }
                }
            }
        },
        methods: {
            constructValueForSelectRoomDropDown(venueId, roomName, roomId) {
                return venueId + ':' + roomName + ':' + roomId;
            },
            isSelected: function (venueId) {
                let item = this.selected.find(
                    (item) => parseInt(item.venue_id) === parseInt(venueId)
                );

                return !!item;
            },
            getCoords(venueRooms) {
                let lat = 0;
                let lng = 0;

                if (venueRooms?.length) {
                    lat = venueRooms[0].lat || 0;
                    lng = venueRooms[0].lng || 0;
                }

                return {
                    lat: parseFloat(lat),
                    lng: parseFloat(lng),
                };
            },
            getMarker(venueRooms) {
                if (!venueRooms?.length) {
                    return "http://maps.google.com/mapfiles/ms/icons/red-dot.png";
                }

                let venueId = venueRooms[0]?.venue_id;
                if (this.isSelected(venueId)) {
                    return "http://maps.google.com/mapfiles/ms/icons/green-dot.png";
                } else {
                    return "http://maps.google.com/mapfiles/ms/icons/red-dot.png";
                }
            },
            updateSelected(selectedLocation) {
                if (this.shouldSelectSpaces && ! this.spacesSelected[selectedLocation.id]) {
                    return false;
                }

                const space = this.spacesSelected[selectedLocation.id];
                if (this.shouldSelectSpaces) {
                    selectedLocation['space'] = space;

                }

                if (!!selectedLocation.selected) {
                    this.$emit("remove", selectedLocation.rooms[space]);
                }

                if (!selectedLocation.selected) {
                    this.$emit("select", selectedLocation.rooms[space]);
                }

                this.closeInfoWindow();
            },
            openInfoWindow(venueRooms) {
                if (! venueRooms?.length) {
                    return;
                }

                let venueId = venueRooms[0].venue_id;
                let venueName = venueRooms[0].venue_name;

                this.infoWindowPos = this.getCoords(venueRooms);
                let rooms = {};
                venueRooms.forEach((room) => rooms[room.room_name] = room);

                this.selectedLocation.id = venueId;
                this.selectedLocation.name = venueName;
                this.selectedLocation.rooms = rooms;
                this.selectedLocation.thumbNail = venueRooms[0]?.thumb_nail;
                this.infoBoxOpen = true;

                if (this.isSelected(venueId)) {
                    this.selectedLocation.selected = true;
                    this.selectedLocation.btnText = "Remove";
                } else {
                    this.selectedLocation.selected = false;
                    this.selectedLocation.btnText = this.addToListLabel;
                }
            },
            addBtnClass(venue) {
                if (this.isSelected(venue)) {
                    return "danger";
                } else {
                    if (!this.shouldSelectSpaces || this.spacesSelected[venue.id]) {
                        return "primary";
                    } else {
                        return "light";
                    }
                }
            },
            closeInfoWindow() {
                this.infoBoxOpen = false;
            },
            getLocation(venueRooms) {
                if (! Object.keys(venueRooms)) {
                    return '';
                }

                const firstRoomName = Object.keys(venueRooms).at(0);

                return `${venueRooms[firstRoomName]?.city}, ` + `${venueRooms[firstRoomName]?.parent_city}`?.toUpperCase();
            },
            selectASpaceForVenue(value) {
                value = value.split(':');
                const [venueId, roomName, roomId] = value;
                this.spacesSelected[venueId] = roomName;
                this.selectedLocation.room_id = roomId ? parseInt(roomId) : null;

                // Find the thumbnail of the selected room
                const selectedRoom = this.selectedLocation.rooms[roomName];
                if (selectedRoom) {
                    this.selectedLocation.thumbNail = this.selectedRoomThumbnail(roomName);
                }
            },
        },
        computed: {
            selectedRoomThumbnail() {
                return (roomName) => {
                    return this.selectedLocation.rooms[roomName]?.image_url ?? this.selectedLocation.rooms['Entire Venue']?.thumb_nail;
                }
            }
        }
    };
</script>

<style lang="scss">
    .venues-list {
        h4 {
            font-weight: normal;
        }

        img {
            width: 100%;
        }

        .thumbnail img {
            max-width: 125px;
        }

        .description {
            font-size: 14px;
        }

        .info-window {
            width: 250px;
            padding: 0;
        }

        .info-window-body {
            padding: 8px;
        }

        .img-wrapper {
            height: 180px;
            overflow: hidden;
        }

        .gm-style-iw {
            padding: 0 !important;
            height: 300px;
        }

        .gm-style-iw-d {
            overflow: auto !important;
        }

        .truncate {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    .venue-map-view-with-spaces {
        .gm-style-iw {
            height: 450px;
        }

        .info-window {
            .el-select {
                width: 100%;
                margin-bottom: 10px !important;
            }
        }
    }
</style>