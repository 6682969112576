<template>
    <div class="content" v-loading.fullscreen="loading">
		<div class="header d-flex align-items-center profile-header">
			<!-- Mask -->
			<span class="mask bg-gradient-info"></span>
			<!-- Header container -->
			<div class="container-fluid d-flex align-items-center">
				<div class="row">
					<div class="col-lg-12">
						<router-link :to="{ name: 'LeadDetails', params: { id: leadId }}">
							<h1 class="display-3 text-white">{{ lead.first_name }} {{lead.last_name}}</h1>
						</router-link>
					</div>
				</div>
			</div>
		</div>
		<base-header class="pb-6">
			<div class="row align-items-center py-4">
				<div class="col-lg-6 col-7">
					<h6 class="h2 text-white d-inline-block mb-0">Karen</h6>
					<nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
						<route-bread-crumb></route-bread-crumb>
					</nav>
				</div>
			</div>
		</base-header>
		<div class="container-fluid mt--6">
			<div class="card no-border-card">
				<div class="card-header">
					<div class="row">
						<div class="page-purpose" :class="{'col-3': isListView, 'col-9': isMapView}">
							<h3>Manage Lead Recommendations</h3>
                        </div>
                        <div class="col-6 text-right" v-show="isListView">
                            <div class="row">
                                <div class="col" id="search_venue">
                                    <base-input
                                        v-model="searchString"
                                        append-icon="fas fa-search"
                                        placeholder="Type a venue name and press ENTER"
                                    ></base-input>
                                    <el-tooltip placement="top" effect="light">
                                        <div slot="content" class="text-center">
                                            Proceed to recommendations <br />submission
                                        </div>
                                        <el-button size="large" @click="showRecommendationSendModal" icon="el-icon-check"
                                            class="show-recommendation-send-modal"
                                            :class="{'queue-has-item': this.roomsSelectedForRecommendation.length}">
                                            {{ this.roomsSelectedForRecommendation.length ? this.roomsSelectedForRecommendation.length : 0 }}/{{ maxRecommendationQueueSize }} Selected
                                        </el-button>
                                    </el-tooltip>
                                    <el-button size="large" icon="el-icon-s-operation" @click="showFilter = true" class="show-filters">
                                        Filters
                                    </el-button>
                                </div>
                            </div>
						</div>

                        <div class="col-3 text-right" id="listing_view_selector">
                            <a href="#" class="btn btn-sm btn-primary"
                                id="karen_venue_list_view"
                                @click=" showMapView = false; showListView = true"
                            ><i class="fas fa-list"></i> List View</a>
                            <a href="#" class="btn btn-sm btn-primary"
                                id=karen_venue_map_view
                                @click="showListView = false; showMapView = true"
                            ><i class="far fa-map"></i> Map View</a>
                        </div>
					</div>
				</div>
				<div class="card-body px-0 pb-1">
					<div class="col-12 mb-4" >
						<el-alert type="info" :closable="false" class="venues-alert">
							<strong v-if="this.roomsSelectedForRecommendation.length < maxRecommendationQueueSize">
								Please select up to {{ maxRecommendationQueueSize }} venues to proceed to recommendations.
							</strong>
							<strong v-if="this.roomsSelectedForRecommendation.length == maxRecommendationQueueSize" class="text-success">
								{{ maxRecommendationQueueSize }}/{{ maxRecommendationQueueSize }} Venues are selected.
							</strong>
							Refine filters or proceed to send.
						</el-alert>
					</div>
                    <KarenV2Listing v-show="isListView"
                        :lead-event-id="leadEventId"
                        :is-loading.sync="loading"
                        :searchedVenueName="searchString"
                    ></KarenV2Listing>
                    <div class="col-12">
                        <keep-alive>
                            <RecommendationMapView class="row" v-if="isMapView"
                                :venues="venuesForMapView"
                                :leadEventId="leadEventId"
                                :queuedRooms="roomsSelectedForRecommendation"
                            ></RecommendationMapView>
                        </keep-alive>
                    </div>

				</div>
			</div>
		</div>

        <!-- Send recommendation modal -->
        <modal :show.sync="modalShowSendRecommendation" body-classes="p-4"
			modal-classes="modal-dialog-centered modal-md">
			<send-recommendation
				:leadEventId="leadEventId"
                queuedVenuesContainerId="queued_venues_list_view"
				@previewed="modalShowSendRecommendation = false"
			/>
		</modal>

        <!-- Search Filters -->
        <FilterOptions
			show-filter="showFilter"
			:showFilter.sync="showFilter"
			:search-criteria="searchCriteria"
			:selectable-locations.sync="cities"
			:searchCriteria.sync="searchCriteria"
			:currentSearchCriteria.sync="currentSearchCriteria"
			:defaultSearchCriteria.sync="defaultSearchCriteria"
			@resetSearchFilter="selectDefaultFilter"
			@selectCurrentFilter="selectCurrentFilter"
		/>

         <!-- Modal to collect missing data -->
        <CorrectInvalidData
			:show-filter="showInitialFilter"
			:showFilter.sync="showInitialFilter"
            :location-is-invalid="invalidData.location"
            :guest-count-is-invalid="invalidData.guest_count"
            :selectable-locations="cities"
			:searchCriteria.sync="searchCriteria"
            :currentSearchCriteria.sync="currentSearchCriteria"
			:defaultSearchCriteria.sync="defaultSearchCriteria"
        />
    </div>
</template>

<script>
import { RouteBreadCrumb } from '@/components';
import { Alert, Button } from 'element-ui';
import KarenV2Listing from './KarenV2Listing.vue';
import recommendationMixin from '@/helpers/recommendationMixin';
import SendRecommendation from '@/views/Widgets/Recommendations/SendRecommendation.vue';
import FilterOptions from '../Karen/FilterOptions.vue';
import karenService from '@/services/karen.service';
import CorrectInvalidData from '@/views/Widgets/KarenV2/CorrectInvalidData.vue';
import VenueRecommendedMap from '@/views/Widgets/Recommendations/VenueRecommendedMap.vue';
import RecommendationMapView from '@/views/Widgets/Recommendations/RecommendationMapView.vue';
import { maxQueueLength } from '@/services/recommendation.service';

export default {
    components: {
        KarenV2Listing,
		RouteBreadCrumb,
		[Button.name]: Button,
		[Alert.name]: Alert,
		SendRecommendation,
		FilterOptions,
		CorrectInvalidData,
        RecommendationMapView,
        MapView: VenueRecommendedMap
	},
    mixins: [recommendationMixin],
    data() {
        return {
			modalShowSendRecommendation: false,
			showInitialFilter: false,
			showFilter: false,
            searchString: '',
            loading: false,
            showListView: true,
            showMapView: false,
			searchCriteria: {},
            currentSearchCriteria: {},
			defaultSearchCriteria: {},
			cities: [],
			venueStyles: [],
            invalidData: {
                location: false,
                guest_count: false
            },
        }
    },
    async mounted() {
        this.loading = true;

        await this.fetchRecommendedVenues();


        try {
            let results = await this.searchVenues(this.$route.params.id, this.leadEventId);
            if (results.data) {
                this.updateData(results.data);
            }
        } catch (error) {
            if (error.response?.status == 422) {
                const failedDataPoints = error.response.data?.dataPoints;
                if (failedDataPoints.includes('location')) {
                    this.invalidData.location = true;
                }

                if (failedDataPoints.includes('guest_count')) {
                    this.invalidData.guest_count = true;
                }

                if (failedDataPoints.length > 0) {
                    await this.loadEventLocations();
                    this.showInitialFilter = true;
                }
            }
        }

        this.loading = false;
        this.$emit('fetched-venues');
    },
    async created() {
        await this.$store.dispatch("venues/getAll", {lead_id: this.leadId});
    },
    computed: {
        maxRecommendationQueueSize() {
            return maxQueueLength;
        },
        lead() {
			return this.$store.getters["leads/getLeadById"](this.$route.params.id);
		},
        leadId() {
            return this.$route.params.id;
        },
        leadEventId() {
            return this.$route.params.eventId ? parseInt(this.$route.params.eventId) : null;
        },
        venuesForMapView() {
            let karenVenuesForListView = this.$store.getters['recommendations/getKarenVenuesForListView']('all');
            let venues = [];
            for (const venueLocationGroupName in karenVenuesForListView) {
                if (['eventCityVenues', 'otherVenues'].includes(venueLocationGroupName)) {
                    venues = [...venues, ...karenVenuesForListView[venueLocationGroupName]];
                }
            }

            return venues;
        },
        isListView() {
            return this.showListView == true && this.showMapView == false;
        },
        isMapView() {
            return this.showListView == false && this.showMapView == true;
        }
    },
    methods: {
        async loadEventLocations() {
			let response = await karenService.getSelectableEventLocations(
				this.leadEventId
			);

			if (response?.data?.selectableLocations) {
                this.cities = response.data.selectableLocations;
			}
		},

		showRecommendationSendModal() {
			if (this.roomsSelectedForRecommendation.length) {
				this.modalShowSendRecommendation = true;
			}
		},

        async fetchRecommendedVenues() {
            try {
                await this.$store.dispatch(
                    "recommendations/getRecommendations",
                    this.leadEventId
                );
            } catch (error) {
                console.log(error);
            }
        },

        selectDefaultFilter() {
			this.searchCriteria = {};
			this.$nextTick(() => {
				this.searchCriteria = Object.assign({}, this.defaultSearchCriteria);
			});
		},

        selectCurrentFilter() {
			this.searchCriteria = {};
			this.$nextTick(() => {
				this.searchCriteria = Object.assign({}, this.currentSearchCriteria);
			});
		},
    }
}
</script>

<style lang="scss">
    div.card-header > .row > .col-3.page-purpose {
        display: flex;
        align-items: center;
    }

    .venues-alert {
        background-color: #F6F9FC !important;
        padding: 15px;

        .el-alert__content {
            .el-alert__description {
                font-family: 'Effra';
                font-size: 16px;
                margin-top: 0px;
                color: #24272C;

                strong {
                    font-weight: 500;
                }
            }
        }
    }

    div#search_venue {
        display: flex;
        & > span {
            flex-grow: 1;
            margin-right: 3px;
            & > div.form-group {
                margin-bottom: 0px;
            }
        }
    }

    div#listing_view_selector {
        display: flex;
        align-items: center;
        justify-content: end;
    }
</style>